import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider ,Navigate } from "react-router-dom";
import { Clinic } from './pages/clinic/clinic';
import { NotFound } from "./pages/not-found/not-found";
import { ResizeContextProvider } from "./context/resize-context";
import './i18n';
import './styles/common.css'
import { Team } from './pages/team/team';
import { TeamMember } from './pages/team-member/team-member';
import { Services } from './pages/services/services';
import { ServiceItem } from './pages/service-item/service-item';
import { CosmetologyDevices } from './pages/cosmetology-devices/cosmetology-devices';
import { CosmetologyDeviceItem } from './pages/cosmetology-device-item/cosmetology-device-item';
import { Brands } from './pages/brands/brands';
import {AboutUs} from "./pages/about-us/about-us";
import {PrivacyPolicy} from "./pages/privacy-policy/privacy-policy";
import {Cookie} from "./pages/cookie/cookie";
import {Contacts} from "./pages/contacts/contacts";
import { HelmetProvider } from 'react-helmet-async';



const routes = [
    {path: "/", element: <Clinic/>},
    {path: "/team/", element: <Team/>},
    {path: "/services/", element: <Services/>},
    {path: "/services/:key/", element: <ServiceItem/>},
    {path: "/device/", element: <CosmetologyDevices/>},
    {path: "/device/:key", element: <CosmetologyDeviceItem/>},
    // {path: "/brands/", element: <Brands/>},
    {path: "/about-us/", element: <AboutUs/>},
    {path: "/privacy-policy/", element: <PrivacyPolicy/>},
    {path: "/cookie/", element: <Cookie/>},
    {path: "/contacts/", element: <Contacts/>},
    {path: "/team/:key/", element: <TeamMember/>},
    {path: "*", element: <NotFound/>},
];

const redirectRoutes = [
    { path: "/team", element: <Navigate to="/team/" replace /> },
    { path: "/services", element: <Navigate to="/services/" replace /> },
    { path: "/services/:key", element: <Navigate to="/services/:key/" replace /> },
    { path: "/device", element: <Navigate to="/device/" replace /> },
    { path: "/device/:key", element: <Navigate to="/device/:key/" replace /> },
    // { path: "/brands", element: <Navigate to="/brands/" replace /> },
    { path: "/about-us", element: <Navigate to="/about-us/" replace /> },
    { path: "/privacy-policy", element: <Navigate to="/privacy-policy/" replace /> },
    { path: "/cookie", element: <Navigate to="/cookie/" replace /> },
    { path: "/contacts", element: <Navigate to="/contacts/" replace /> },
    { path: "/team/:key", element: <Navigate to="/team/:key/" replace /> },
];

const router = createBrowserRouter([...routes, ...redirectRoutes]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>

    <ResizeContextProvider>
        <HelmetProvider>
                <RouterProvider router={router}/>

        </HelmetProvider>
        </ResizeContextProvider>
    </>
);

reportWebVitals();
