import React, {memo, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Page} from "../../components/page";
import {services} from '../../shared/configs/services.config';
import {useTranslation} from "react-i18next";

import '../../styles/components/service-item.css'
import Button from "../../shared/components/button";
import {bookVisitLink} from "../../constants";
import {Helmet} from "react-helmet-async";
import servicePageBannerMobile from "../../assets/banners/service-page-banner-mobile.jpg";
import logo from "../../assets/leo-clinic.webp";
import Breadcrumbs from "../../components/breadcrumbs";

export const ServiceItem = memo(() => {
    const {t} = useTranslation();

    const {key} = useParams();

    const [service, setService] = useState(null);

    useEffect(() => {
        const foundService = services.find(el => el.key === key);
        setService(foundService);
    }, [key]);

    const jsonLdData = {
        "@context": "http://schema.org",
        "@type": "Organization",
        "name": "Leo beauty clinic",
        "url": window.location.href,
        "logo": `${window.location.origin}${logo}`,
        "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+48224244646",
            "contactType": "Customer Service",
        },
    };


    return (
        <Page>
            <Breadcrumbs />
            <div className="service-wraper full-width-content">
                <div className="left-column">
                    <div>
                        {service ? (
                            <>
                                <Helmet>
                                    <title>{t(`services.${service.key}.title`)} ᐉ Bezpłatne konsultacje na Leobeautyclinic.pl</title>
                                    <meta name="description" content={`➤ ${t(`services.${service.key}.title`)} + 📝 Doświadczeni specjaliści✔️Wysoka jakość świadczenia usług✔️Szeroki wybór zabiegów ✔️Kwalifikowana konsultacja`}
                                    />

                                    <meta property="og:title" content={t(`services.${service.key}.title`)}/>
                                    <meta property="og:description"
                                          content={t(`services.${service.key}.des`)} />
                                    <meta property="og:image" content={`${window.location.origin}${service.imageBig}`}/>
                                    <meta property="og:url" content={window.location.href}/>
                                    <meta property="og:site_name" content="Leo Beauty Clinic"/>
                                    <meta property="og:type" content="website"/>

                                    <script
                                        type="application/ld+json"
                                        dangerouslySetInnerHTML={{__html: JSON.stringify(jsonLdData)}}
                                    />

                                </Helmet>
                                <h1 className="service-item-title">{t(`services.${service.key}.title`)}</h1>
                                <h2 style={{all:"unset"}}> <p className="service-item-des">{t(`services.${service.key}.des`)}</p></h2>
                            </>
                        ) : (
                            <p>Service not found</p>
                        )}
                    </div>
                </div>
                <div className="right-column">
                    {service ? (
                        <img src={service.imageBig} alt="Description of Image"/>
                    ) : (
                        <p>Service not found</p>
                    )}
                </div>
            </div>

            <div className="page-container service-procedures-offered">
                <div className="procedures-title">
                    <h3 style={{all:"unset"}}>{t('services.procedures_offered')}:</h3>
                </div>
                <div className="procedures-list-grid ">
                    <div className="procedures-list-grid-item">
                        {service ? (
                            <div
                                dangerouslySetInnerHTML={{__html: t(`services.${service.key}.procedures_offered_left`)}}/>

                        ) : (
                            <p>Service not found</p>
                        )}

                    </div>
                    <div className="procedures-list-grid-item">
                        {service ? (
                            <div
                                dangerouslySetInnerHTML={{__html: t(`services.${service.key}.procedures_offered_right`)}}/>
                        ) : (
                            <div></div>
                        )}
                    </div>

                </div>
                <div className="row book-visit-btn">
                    <Button onClick={() => window.open(bookVisitLink)}>
                        {t('common.book_visit')}
                    </Button>
                </div>
            </div>

        </Page>
    );
});