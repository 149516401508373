import React from 'react';
import '../../styles/components/privacy-policy.css'
import {Page} from "../../components/page";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";
import Breadcrumbs from "../../components/breadcrumbs";


export const PrivacyPolicy = () => {
    const {t,i18n } = useTranslation();

    const currentLanguage = i18n.language;
    let policyText;
    if (currentLanguage === "pl") {
        policyText = (
            <>
                <h4 className="privacy-span-mobile">Polityka prywatności opisuje zasady przetwarzania przez nas informacji o Tobie, w tym danych osobowych oraz plik&oacute;w cookies, czyli tzw. ciasteczek.</h4>
                <h4>1. Informacje og&oacute;lne</h4>
                <p>Niniejsza polityka dotyczy Serwisu www, funkcjonującego pod adresem url: the-clinic.pl</p>
                <p>Operatorem strony i administratorem danych osobowych jest: The Clinic Sp. z o. o. ul. Londyńska 4/5, 03-921 Warszawa</p>
                <p>Adres poczty elektronicznej Operatora: <a data-fr-linked="true" href="mailto:kontakt@the-clinic.pl">kontakt@the-clinic.pl</a></p>
                <p>Operator jest Administratorem Twoich danych osobowych w odniesieniu do danych podanych dobrowolnie w Serwisie.</p>
                <p><br/></p>
                <p><br/></p>
                <h4>Serwis wykorzystuje dane osobowe w następujących celach:</h4>
                <p>Prowadzenie newslettera</p>
                <p>Prowadzenie rozm&oacute;w na czacie online</p>
                <p>Obsługi zapytań poprzez formularz</p>
                <p>Przygotowania, pakowania i wysyłki towar&oacute;w</p>
                <p>Realizacja zam&oacute;wionych usług</p>
                <p>Prezentacja oferty lub informacji</p>
                <p>Serwis realizuje funkcje pozyskiwania informacji o użytkownikach i ich zachowaniu w następujący spos&oacute;b.</p>
                <p>Poprzez dobrowolnie wprowadzone w formularzach dane, kt&oacute;re zostają wprowadzone do system&oacute;w Operatora.</p>
                <p>Poprzez zapisywanie w urządzeniach końcowych pliki cookie (tzw. &bdquo;ciasteczka&rdquo;).</p>
                <p><br/></p>
                <p><br/></p>
                <h4>2. Wybrane metody ochrony danych stosowane przez Operatora</h4>
                <p>Miejsca logowania i wprowadzania danych osobowych są chronione w warstwie transmisji (certyfikat SSL). Dzięki temu dane osobowe i dane logowania wprowadzone na stronie są zaszyfrowane w komputerze użytkownika i mogą być odczytane jedynie na docelowym serwerze.</p>
                <p><br/></p>
                <p><br/></p>
                <p>Hasła użytkownik&oacute;w przechowywane są w postaci hashowanej. Funkcja hashująca działa jednokierunkowo - niemożliwe jest odwr&oacute;cenie jej działania, co jest obecnie nowoczesnym standardem przechowywania haseł użytkownik&oacute;w.</p>
                <p><br/></p>
                <p><br/></p>
                <p>Istotnym elementem ochrony danych jest regularna aktualizacja wszelkiego oprogramowania wykorzystywanego przez Operatora do przetwarzania danych osobowych, co w szczeg&oacute;lności oznacza regularne aktualizacje komponent&oacute;w programistycznych.</p>
                <p><br/></p>
                <p><br/></p>
                <h4>3. Hosting</h4>
                <p>Serwis jest hostowany (technicznie utrzymywany) na serwerach Operatora: nazwa.pl</p>
                <p><br/></p>
                <h4>4. Twoje prawa i dodatkowe informacje o sposobie wykorzystania Twoich danych</h4>
                <p>W niekt&oacute;rych sytuacjach Administrator ma prawo przekazywać Twoje dane osobowe innym odbiorcom, jeśli będzie to niezbędne do wykonania zawartej z Tobą umowy lub do zrealizowania obowiązk&oacute;w ciążących na Administratorze. Dotyczy to następujących grup odbiorc&oacute;w:</p>
                <p>firma hostingowa na zasadzie powierzenia</p>
                <p>kurierzy</p>
                <p>operatorzy pocztowi</p>
                <p>banki</p>
                <p>operatorzy płatności</p>
                <p><br/></p>
                <p><br/></p>
                <p><br/></p>
                <p>upoważnionym pracownikom i wsp&oacute;łpracownikom, kt&oacute;rzy wykorzystują dane do realizacji celu strony internetowej</p>
                <p>firmy świadczące usługi marketingowe na rzecz Administratora</p>
                <p>Twoje dane osobowe przetwarzane przez Administratora nie dłużej niż jest to niezbędne do wykonania związanych z nimi czynności określonych w odrębnych przepisach (np. o rachunkowości). W odniesieniu do danych marketingowych dane nie będą przetwarzane dłużej niż 3 lata.</p>
                <p><br/></p>
                <p><br/></p>
                <p>Przysługuje Ci prawo do żądania od Administratora:</p>
                <p>dostępu do danych osobowych dotyczących Ciebie,</p>
                <p>ich sprostowania,</p>
                <p>usunięcia,</p>
                <p>ograniczenia przetwarzania,</p>
                <p>oraz przenoszenia danych.</p>
                <p><br/></p>
                <p><br/></p>
                <p>Przysługuje Ci prawo do złożenia sprzeciwu w zakresie przetwarzania wskazanego w pkt 3.3 c) wobec przetwarzania danych osobowych w celu wykonania prawnie uzasadnionych interes&oacute;w realizowanych przez Administratora, w tym profilowania, przy czym prawo sprzeciwu nie będzie mogło być wykonane w przypadku istnienia ważnych prawnie uzasadnionych podstaw do przetwarzania, nadrzędnych wobec Ciebie interes&oacute;w, praw i wolności, w szczeg&oacute;lności ustalenia, dochodzenia lub obrony roszczeń.</p>
                <p><br/></p>
                <p>Przysługuje Ci prawo wniesienia skargi na działania Administratora do Prezesa Urzędu Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa.</p>
                <p><br/></p>
                <p><br/></p>
                <p>Podanie danych osobowych jest dobrowolne, ale niezbędne do obsługi Serwisu.</p>
                <p><br/></p>
                <p><br/></p>
                <p>W stosunku do Ciebie mogą być podejmowane czynności polegające na zautomatyzowanym podejmowaniu decyzji, w tym profilowaniu, w celu świadczenia usług w ramach zawartej umowy oraz w celu prowadzenia przez Administratora marketingu bezpośredniego.</p>
                <p><br/></p>
                <p><br/></p>
                <p>Dane osobowe nie są przekazywane z państw trzecich w rozumieniu przepis&oacute;w o ochronie danych osobowych. Oznacza to, że nie wysyłamy ich poza Unię Europejską.</p>
                <p><br/></p>
                <p><br/></p>
                <h4>5. Informacje w formularzach</h4>
                <p>Serwis zbiera informacje podane dobrowolnie przez użytkownika, w tym dane osobowe, o ile zostaną one podane.</p>
                <p><br/></p>
                <p><br/></p>
                <p>Serwis może zapisać informacje o parametrach połączenia (oznaczenie czasu, adres IP).</p>
                <p><br/></p>
                <p><br/></p>
                <p>Serwis, w niekt&oacute;rych wypadkach, może zapisać informację ułatwiającą powiązanie danych w formularzu z adresem e-mail użytkownika wypełniającego formularz. W takim przypadku adres e-mail użytkownika pojawia się w adresie URL strony zawierającej formularz.</p>
                <p><br/></p>
                <p><br/></p>
                <p>Dane podane w formularzu są przetwarzane w celu wynikającym z funkcji konkretnego formularza, np. w celu dokonania procesu obsługi zgłoszenia serwisowego lub kontaktu handlowego, rejestracji usług itp. Każdorazowo kontekst i opis formularza w czytelny spos&oacute;b informuje, do czego on służy.</p>
                <p><br/></p>
                <p><br/></p>
                <h4>6. Dzienniki administratora</h4>
                <p>Informacje o zachowaniu użytkownika mogą podlegać logowaniu w serwisie. Dane te są wykorzystywane w celu administrowania serwisem.</p>
                <p><br/></p>
                <h4>7. Ważne techniki marketingowe</h4>
                <p>Operator korzysta z analizy statystycznej ruchu na stronie internetowej za pośrednictwem Google Analytics (Google Inc. z siedzibą w USA). Operator nie przekazuje operatorowi tej usługi danych osobowych, a jedynie zanonimizowane informacje. Usługa opiera się na wykorzystaniu plik&oacute;w cookies na urządzeniu końcowym użytkownika. W zakresie informacji o preferencjach użytkownika gromadzonych przez sieć reklamową Google użytkownik może przeglądać i edytować informacje wynikające z plik&oacute;w cookies przy pomocy narzędzia: <a data-fr-linked="true" href="https://www.google.com/ads/preferences/">https://www.google.com/ads/preferences/</a></p>
                <p><br/></p>
                <p><br/></p>
                <p>Operator stosuje techniki remarketingowe pozwalające na dopasowanie przekazu reklamowego do zachowania użytkownika na stronie, co może dawać złudzenie, że dane osobowe użytkownika są wykorzystywane do jego śledzenia, jednak w praktyce żadne dane osobowe nie są przekazywane przez Operatora operatorom reklamowym. Warunkiem technologicznym takich działań jest włączenie obsługi plik&oacute;w cookie.</p>
                <p><br/></p>
                <p><br/></p>
                <p>Operator korzysta z piksela Facebooka. Technologia ta pozwala Facebookowi (Facebook Inc. z siedzibą w USA) wiedzieć, że dana osoba tam zarejestrowana korzysta z Serwisu. W tym przypadku opiera się na danych, kt&oacute;rych sam jest administratorem; Operator nie przekazuje Facebookowi żadnych dodatkowych danych osobowych. Usługa opiera się na wykorzystaniu plik&oacute;w cookies na urządzeniu końcowym użytkownika.</p>
                <p><br/></p>
                <p><br/></p>
                <p>Operator korzysta z rozwiązania badającego zachowania użytkownik&oacute;w poprzez tworzenie map ciepła i rejestrowanie zachowań na stronie internetowej. Informacje te są anonimizowane przed ich przesłaniem do operatora serwisu, dzięki czemu nie wie on, jakiej osoby fizycznej dotyczą. W szczeg&oacute;lności wprowadzane hasła i inne dane osobowe nie są rejestrowane.</p>
                <p><br/></p>
                <p><br/></p>
                <p>Operator stosuje rozwiązanie automatyzujące działanie Serwisu dla użytkownik&oacute;w, np. mogące wysłać do użytkownika wiadomość e-mail po odwiedzeniu określonej podstrony, o ile wyraził on zgodę na otrzymywanie korespondencji handlowej od Operatora.</p>
                <p><br/></p>
                <p><br/></p>
                <h4>8. Informacja o plikach cookies</h4>
                <p>Serwis wykorzystuje pliki cookies.</p>
                <p><br/></p>
                <p><br/></p>
                <p>Pliki cookies (tzw. &bdquo;ciasteczka&rdquo;) stanowią dane informatyczne, w szczeg&oacute;lności pliki tekstowe, kt&oacute;re przechowywane są w urządzeniu końcowym Użytkownika Serwisu i przeznaczone są do korzystania ze stron internetowych Serwisu. Cookies zazwyczaj zawierają nazwę strony internetowej, z kt&oacute;rej pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer.</p>
                <p><br/></p>
                <p><br/></p>
                <p>Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika Serwisu pliki cookies oraz uzyskującym do nich dostęp jest operator Serwisu.</p>
                <p><br/></p>
                <p><br/></p>
                <p>Pliki cookies wykorzystywane są w następujących celach:</p>
                <p><br/></p>
                <p><br/></p>
                <p>utrzymanie sesji Użytkownika Serwisu (po zalogowaniu), dzięki kt&oacute;rej Użytkownik nie musi na każdej podstronie Serwisu ponownie wpisywać loginu i hasła;</p>
                <p><br/></p>
                <p>realizacji cel&oacute;w określonych w sekcji &bdquo;Istotne Techniki Marketingowe&rdquo; powyżej;</p>
                <p><br/></p>
                <p>W ramach Strony stosowane są dwa zasadnicze rodzaje plik&oacute;w cookies: &bdquo;sesyjne&rdquo; (session cookies) oraz &bdquo;stałe&rdquo; (persistent cookies). Cookies &bdquo;sesyjne&rdquo; są plikami tymczasowymi, kt&oacute;re przechowywane są w urządzeniu końcowym Użytkownika do czasu wylogowania, opuszczenia strony internetowej lub wyłączenia oprogramowania (przeglądarki internetowej). &bdquo;Stałe&rdquo; pliki cookies przechowywane są w urządzeniu końcowym Użytkownika przez czas określony w parametrach plik&oacute;w cookies lub do czasu ich usunięcia przez Użytkownika.</p>
                <p><br/></p>
                <p><br/></p>
                <p>Oprogramowanie do przeglądania stron internetowych (przeglądarka internetowa) zazwyczaj domyślnie dopuszcza przechowywanie plik&oacute;w cookies w urządzeniu końcowym Użytkownika. Użytkownicy serwisu mogą dokonać zmiany ustawień w tym zakresie. Przeglądarka internetowa umożliwia usunięcie plik&oacute;w cookies. Możliwe jest także automatyczne blokowanie plik&oacute;w cookies. Szczeg&oacute;łowe informacje na ten temat zawiera pomoc lub dokumentacja przeglądarki internetowej.</p>
                <p><br/></p>
                <p><br/></p>
                <p>Ograniczenia stosowania plik&oacute;w cookies mogą wpłynąć na niekt&oacute;re funkcjonalności dostępne na stronach internetowych Serwisu.</p>
                <p><br/></p>
                <p><br/></p>
                <p>Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika Serwisu i wykorzystywane mogą być r&oacute;wnież przez wsp&oacute;łpracujące z operatorem Serwisu podmioty, w szczeg&oacute;lności dotyczy to firm Google (Google Inc. z siedzibą w USA), Facebook (Facebook Inc. z siedzibą w USA), Twitter (Twitter Inc. z siedzibą w USA).</p>
                <p><br/></p>
                <p><br/></p>
                <p><br/></p>
                <h4>9. Zarządzanie plikami cookies - jak w praktyce wyrażać i cofać zgodę?</h4>
                <p>Jeśli nie chcesz otrzymywać plik&oacute;w cookies, możesz zmienić ustawienia swojej przeglądarki. Zastrzegamy, że wyłączenie obsługi plik&oacute;w cookies niezbędnych dla proces&oacute;w uwierzytelniania, bezpieczeństwa, utrzymania preferencji użytkownika może utrudnić, a w skrajnych przypadkach może uniemożliwić korzystanie ze stron www.</p>
                <p><br/></p>
                <p><br/></p>
                <p>W celu zarządzania ustawieniami cookies wybierz z listy poniżej przeglądarkę internetową, kt&oacute;rej używasz i postępuj zgodnie z instrukcjami:</p>
                <p><br/></p>
                <p><br/></p>
                <p>Edge</p>
                <p>Internet Explorer</p>
                <p>Chrome</p>
                <p>Safari</p>
                <p>Firefox</p>
                <p>Opera</p>
                <p>Urządzenia mobilne:</p>
                <p><br/></p>
                <p>Android</p>
                <p>Safari (iOS)</p>
                <p>Windows Phone</p>
               <br/>
                <br/>
            </>
        );
    } else {
        policyText = (
            <>
                <h4 className="privacy-span-mobile">The privacy policy describes the principles of our processing of information about you, including
                    personal data and cookies, i.e. the so-called cookies.</h4>
                <h4>1. General information</h4>
                <p>This policy applies to the website operating at the url address: the-clinic.pl</p>
                <p>The website operator and personal data administrator is: The Clinic Sp. z o. o. ul. Londyńska 4/5,
                    03-921 Warszawa</p>
                <p>Operator&apos;s e-mail address: <a data-fr-linked="true"
                                                      href="mailto:kontakt@the-clinic.pl">kontakt@the-clinic.pl</a></p>
                <p>The Operator is the Administrator of your personal data in relation to data provided voluntarily on
                    the Website.</p>
                <br/>
                <br/>
                <h4>The website uses personal data for the following purposes:</h4>
                <p>Maintaining a newsletter</p>
                <p>Conducting online chat conversations</p>
                <p>Handling inquiries via the form</p>
                <p>Preparation, packing and shipping of goods</p>
                <p>Implementation of ordered services</p>
                <p>Presentation of an offer or information</p>
                <p>The website performs the functions of obtaining information about users and their behavior in the
                    following way:</p>
                <p>By voluntarily entering data in forms that are entered into the Operator&apos;s systems.</p>
                <p>By saving cookies on end devices (so-called &quot;cookies&quot;). </p>
                <br/>
                <br/>


                <h4>2. Selected data protection methods used by the Operator</h4>
                <p>The places of logging in and entering personal data are protected in the transmission layer (SSL
                    certificate). Thanks to this, personal data and login details entered on the website are encrypted
                    on the user&apos;s computer and can only be read on the target server.</p>
                <br/>
                <br/>
                <p>User passwords are stored in hashed form. The hash function works in one direction - it is impossible
                    to reverse its operation, which is currently the modern standard for storing user passwords.</p>
                <br/>
                <br/>
                <p>An important element of data protection is the regular updating of all software used by the Operator
                    to process personal data, which in particular means regular updates of programming components.</p>
                <br/>
                <br/>
                <h4>3. Hosting</h4>
                <p>The website is hosted (technically maintained) on the operator&apos;s servers: nazwa.pl</p>
                <br/>
                <br/>
                <h4>4. Your rights and additional information about how your data is used</h4>
                <p>In some situations, the Administrator has the right to transfer your personal data to other
                    recipients if it is necessary to perform the contract concluded with you or to fulfill the
                    obligations imposed on the Administrator. This applies to the following target groups:</p>
                <p>hosting company on an entrusted basis</p>
                <p>couriers</p>
                <p>postal operators</p>
                <p>banks</p>
                <p>payment operators </p>
                <br/>
                <br/>
                <p>authorized employees and collaborators who use data to achieve the purpose of the website</p>
                <p>companies providing marketing services to the Administrator</p>
                <p>Your personal data processed by the Administrator no longer than is necessary to perform related
                    activities specified in separate regulations (e.g. on accounting). With respect to marketing data,
                    data will not be processed for longer than 3 years.</p>
                <br/>
                <br/>
                <p>You have the right to request from the Administrator:</p>
                <p>access to personal data relating to you,</p>
                <p>their corrections,</p>
                <p>deletion,</p>
                <p>processing restrictions,</p>
                <p>and data transfer. </p>
                <br/>
                <br/>
                <p>You have the right to object to the processing indicated in point 3.3 c) to the processing of
                    personal data for the purpose of pursuing legally justified interests pursued by the Administrator,
                    including profiling, but the right to object will not be possible if there are valid legally
                    justified grounds for processing, interests, rights and freedoms that override you, in particular
                    the establishment, exercise or defense of claims.</p>
                <br/>
                <br/>
                <p>You may lodge a complaint against the Administrator&apos;s actions to the President of the Office for
                    Personal Data Protection, ul. Stawki 2, 00-193 Warsaw.</p>
                <br/>
                <br/>
                <p>Providing personal data is voluntary, but necessary to operate the Website.</p>
                <br/>
                <br/>
                <p>Activities involving automated decision-making may be undertaken in relation to you, including
                    profiling, in order to provide services under the concluded contract and for the purpose of
                    conducting direct marketing by the Administrator.</p>
                <br/>
                <br/>
                <p>Personal data is not transferred from third countries within the meaning of personal data protection
                    regulations. This means that we do not send them outside the European Union.</p>
                <br/>
                <br/>
                <h4>5. Information in forms</h4>
                <p>The website collects information provided voluntarily by the user, including personal data, if
                    provided.</p>
                <br/>
                <br/>
                <p>The website may save information about connection parameters (time stamp, IP address).</p>
                <br/>
                <br/>
                <p>In some cases, the website may save information that makes it easier to link the data in the form
                    with the e-mail address of the user completing the form. In this case, the user&apos;s email address
                    appears inside the URL of the page containing the form.</p>
                <br/>
                <br/>
                <p>The data provided in the form is processed for the purpose resulting from the function of a specific
                    form, e.g. to process a service request or a commercial contact, register services, etc. Each time,
                    the context and description of the form clearly inform what it is used for.</p>
                <br/>
                <br/>
                <h4>6. Administrator Logs</h4>
                <p>Information about user behavior may be subject to logging in on the website. This data is used to
                    administer the website.</p>
                <br/>
                <br/>
                <h4>7. Important marketing techniques</h4>
                <p>The operator uses statistical analysis of website traffic via Google Analytics (Google Inc. based in
                    the USA). The operator does not transfer personal data to the operator of this service, only
                    anonymized information. The service is based on the use of cookies on the user&apos;s end device. In
                    terms of information about user preferences collected by the Google advertising network, the user
                    can view and edit information resulting from cookies using the tool: <a data-fr-linked="true"
                                                                                            href="https://www.google.com/ads/preferences/">https://www.google.com/ads/preferences/</a>
                </p>
                <br/>
                <br/>
                <p>The Operator uses remarketing techniques that allow advertising messages to be tailored to the
                    user&apos;s behavior on the website, which may give the illusion that the user&apos;s personal data
                    are used to track him, but in practice, no personal data is transferred from the Operator to
                    advertising operators. The technological condition for such activities is that cookies are
                    enabled.</p>
                <br/>
                <br/>
                <p>The operator uses the Facebook pixel. This technology allows Facebook (Facebook Inc. based in the
                    USA) to know that a given person registered there uses the Website. In this case, it is based on
                    data for which it is itself the administrator; the Operator does not provide any additional personal
                    data to Facebook. The service is based on the use of cookies on the user&apos;s end device.</p>
                <br/>
                <br/>
                <p>The operator uses a solution that examines user behavior by creating heat maps and recording behavior
                    on the website. This information is anonymized before it is sent to the service operator so that it
                    does not know which natural person it relates to. In particular, entered passwords and other
                    personal data are not recorded.</p>
                <br/>
                <br/>
                <p>The Operator uses a solution that automates the operation of the Website for users, e.g., which can
                    send an e-mail to the user after visiting a specific subpage, provided that he or she has agreed to
                    receive commercial correspondence from the Operator.</p>
                <br/>
                <br/>
                <h4>8. Information about cookies</h4>
                <p>The website uses cookies.</p>
                <br/>
                <br/>
                <p>Cookies (so-called &quot;cookies&quot;) are IT data, in particular text files, which are stored on
                    the Website User&apos;s end device and are intended for using the Website&apos;s websites. Cookies
                    usually contain the name of the website they come from, their storage time on the end device and a
                    unique number.</p>
                <br/>
                <br/>
                <p>The entity that places cookies on the Website User&apos;s end device and obtains access to them is
                    the Website operator.</p>
                <br/>
                <br/>
                <p>Cookies are used for the following purposes:</p>
                <br/>
                <br/>
                <p>maintaining the Website user&apos;s session (after logging in), thanks to which the user does not
                    have to re-enter the login and password on each subpage of the Website;</p>
                <p>achieving the objectives set out in the &quot;Substantial Marketing Techniques&quot; section
                    above;</p>
                <p>The Website uses two basic types of cookies: &quot;session cookies&quot; and &quot;persistent
                    cookies&quot;. &quot;Session&quot; cookies are temporary files that are stored on the User&apos;s
                    end device until logging out, leaving the website or turning off the software (web
                    browser). &quot;Permanent&quot; cookies are stored on the User&apos;s end device for the time
                    specified in the cookie parameters or until they are deleted by the User.</p>
                <br/>
                <br/>
                <p>Software for browsing websites (web browser) usually allows cookies to be stored on the User&apos;s
                    end device by default. Website users may change the settings in this regard. The web browser allows
                    you to delete cookies. It is also possible to automatically block cookies. Detailed information on
                    this subject can be found in the help or documentation of your web browser.</p>
                <br/>
                <br/>
                <p>Restrictions on the use of cookies may affect some of the functionalities available on the
                    Website.</p>
                <br/>
                <br/>
                <p>Cookies placed on the Website User&apos;s end device may also be used by entities cooperating with
                    the Website operator, in particular the following companies: Google (Google Inc. based in the USA),
                    Facebook (Facebook Inc. based in the USA), Twitter (Twitter Inc. based in the USA).</p>
                <br/>
                <br/>
                <h4>9. Cookies management &ndash; how to express and withdraw consent in practice?</h4>
                <p>If you do not want to receive cookies, you can change your browser settings. We reserve that
                    disabling the use of cookies necessary for authentication processes, security, maintaining user
                    preferences may make it difficult, and in extreme cases may prevent the use of websites.</p>
                <br/>
                <br/>
                <p>To manage cookie settings, select the web browser you are using from the list below and follow the
                    instructions:</p>
                <br/>
                <br/>
                <p>Edge</p>
                <p>Internet Explorer</p>
                <p>Chrome</p>
                <p>Safari</p>
                <p>Firefox</p>
                <p>Opera</p>
                <p>Mobile devices:</p>
                <br/>
                <br/>
                <p>Android</p>
                <p>Safari (iOS)</p>
                <p>Windows Phone</p>
                <br/>
                <br/>
            </>
        );
    }
    return (
        <Page>
            <Breadcrumbs />
            <Helmet>
                <title>{t('common.privacy')} | Klinika urody w Warszawie, Polsce</title>
            </Helmet>
            <h1 className="privacy-title">{t('common.privacy')}</h1>
            <div className="privacy-page opacity07">
                {policyText}
            </div>
        </Page>
    )
}