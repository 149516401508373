import useServiceManager from '../../../hooks/service.hook';
import ServiceList from '../../../shared/components/service-list';
import React, {useContext} from 'react';
import Button from '../../../shared/components/button';
import ServiceFilter from '../../../shared/components/service-filter';
import {useTranslation} from 'react-i18next';
import {ResizeContext} from '../../../context/resize-context';
import {bookVisitLink} from '../../../constants';
import logo from "../../../assets/leo-clinic.webp";
import {Helmet} from "react-helmet-async";
import {Page} from "../../../components/page";

const AllServices = () => {
    const {t} = useTranslation();
    const {isTablet} = useContext(ResizeContext);

    const {
        filters,
        filteredServices,
        activeFilter,
        setSelectedFilter
    } = useServiceManager({isTablet, isShortView: false});

    const onFilterValueChange = (value) => {
        setSelectedFilter(value);
    };
    return (



            <section className="all-services">
                <ServiceFilter filters={filters}
                               activeFilter={activeFilter}
                               onValueChange={onFilterValueChange}
                >
                </ServiceFilter>
                <ServiceList data={filteredServices}></ServiceList>
                <div className="row book-visit-btn">
                    <Button onClick={() => window.open(bookVisitLink)}>
                        {t('common.book_visit')}
                    </Button>
                </div>
            </section>

    )
}
export default AllServices;