import React, {useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {ResizeContext} from '../../../context/resize-context';
import '../../../styles/components/reviews.css'

import review1 from '../../../assets/reviews/review-1.jpg';
import review2 from '../../../assets/reviews/review-2.jpg';
import review3 from '../../../assets/reviews/review-3.jpg';
import review4 from '../../../assets/reviews/review-4.jpg';
import review5 from '../../../assets/reviews/review-5.jpg';
import review1pl from '../../../assets/reviews/review-1pl.jpg';
import review2pl from '../../../assets/reviews/review-2pl.jpg';
import review3pl from '../../../assets/reviews/review-3pl.jpg';
import review4pl from '../../../assets/reviews/review-4pl.jpg';
import review5pl from '../../../assets/reviews/review-5pl.jpg';

import rev1 from '../../../assets/reviews/rev1.svg';
import rev2 from '../../../assets/reviews/rev2.png';
import rev2add from '../../../assets/reviews/rev2add.jpg';
import rev4 from '../../../assets/reviews/rev4.svg';
import rev5 from '../../../assets/reviews/rev5.svg';

import {Slide} from 'react-slideshow-image';
import sliderArrowLeft from '../../../assets/icons/arrow-left.svg';
import reviewIcon from '../../../assets/icons/reviews.svg';
import {getAdaptiveSlidesCount} from '../../../utils';


const ReviewList = [review1, review2, review3, review4, review5];
const ReviewListPl = [review1pl, review2pl, review3pl, review4pl, review5pl];

export const Reviews = () => {
    const {t, i18n} = useTranslation();
    const {isTablet} = useContext(ResizeContext);
    const [reviews, setReviews] = useState([...ReviewList, ...ReviewList]);

    const customArrowPreviewComponent = <div className='slider-navigation'><img src={sliderArrowLeft} alt="chevron"/>
    </div>;


    const slideWidth = 355 + 20;
    const sliderProperties = {
        autoplay: false,
        prevArrow: customArrowPreviewComponent,
        nextArrow: customArrowPreviewComponent,
        responsive: [
            getAdaptiveSlidesCount(1800, slideWidth),
            getAdaptiveSlidesCount(1440, slideWidth),
            getAdaptiveSlidesCount(1360, slideWidth),
            getAdaptiveSlidesCount(1200, slideWidth),
            getAdaptiveSlidesCount(991, slideWidth),
            getAdaptiveSlidesCount(600, slideWidth),
        ]
    }

    let currentReviewList = i18n.language === 'pl' ? ReviewListPl : ReviewList;

    return (
        <section className="main-reviews-section full-width-content">
            <div className="page-container reviews-info-block">

                <h2 className="section-title">
                    {t('reviews.title')}
                </h2>
                <div className="review-icon">
                    <div className="estimation-block">
                        <a target="_blank" style={{color: "var(--dark-grey)"}}
                           href="https://www.google.com/search?sca_esv=5ab42de3d1d78b1a&tbm=lcl&q=Leo+Beauty+Clinic+Reviews&rflfq=1&num=20&stick=H4sIAAAAAAAAAONgkxI2sTS3NDAyMDczNLQwMDc3NTa22MDI-IpR0ic1X8EpNbG0pFLBOSczLzNZISi1LDO1vHgRK245AGRuj2pSAAAA&rldimm=4979020761180775338&hl=en-PL&sa=X&ved=2ahUKEwij1fCIqoeMAxWMGRAIHW6FBUsQ9fQKegQIMRAF&biw=1280&bih=585&dpr=1.5#lkt=LocalPoiReviews">
                            <span>Google 4.7</span>
                            <img src={reviewIcon} alt={reviewIcon}/>
                        </a>
                    </div>
                    <div className="estimation-block">
                        <a href="https://booksy.com/pl-pl/152695_leo-beauty-clinic_medycyna-estetyczna_3_warszawa#ba_s=seo"
                           target="_blank" style={{color: "var(--dark-grey)"}}>
                            <span>Booksy 4.7</span>
                            <img src={reviewIcon} alt={reviewIcon}/>
                        </a>
                    </div>
                </div>
            </div>
            <div className="reviews-slider-container">
                <Slide {...sliderProperties}>
                    <div className="review-item">
                        <div className="review-item-top">
                            <img src={rev1} width="40" height="40" alt="review icon"/>
                            <div>
                                <p className="review-item-name">Agata Mańkowska</p>
                                <span className="review-item-date">a year ago</span>
                            </div>
                        </div>
                        <p className="review-item-description">{t('reviews.rev1.des')}</p>
                    </div>
                    <div className="review-item">
                        <div className="review-item-top">
                            <img src={rev2} width="40" height="40" alt="review icon"/>
                            <div>
                                <p className="review-item-name">Natalia Stumpło</p>
                                <span className="review-item-date">a year ago</span>
                            </div>
                        </div>
                        <img src={rev2add} style={{paddingBottom: "15px"}} width="100%" alt="review img"/>

                        <p className="review-item-description">{t('reviews.rev2.des')}</p>
                    </div>
                    <div className="review-item">
                        <div className="review-item-top">
                            <img src={rev1} width="40" height="40" alt="review icon"/>
                            <div>
                                <p className="review-item-name">Natalia Stumpło</p>
                                <span className="review-item-date">a year ago</span>
                            </div>
                        </div>
                        <h5 className="review-item-title">{t('reviews.rev3.title')}</h5>
                        <p className="review-item-description">{t('reviews.rev3.des')}</p>
                    </div>
                    <div className="review-item">
                        <div className="review-item-top">
                            <img src={rev4} width="40" height="40" alt="review icon"/>
                            <div>
                                <p className="review-item-name">Violetta Babkina</p>
                                <span className="review-item-date">7 months ago</span>
                            </div>
                        </div>
                        <h5 className="review-item-title">{t('reviews.rev4.title')}</h5>
                        <p className="review-item-description">{t('reviews.rev4.des')}</p>
                    </div>
                    <div className="review-item">
                        <div className="review-item-top">
                            <img src={rev5} width="40" height="40" alt="review icon"/>
                            <div>
                                <p className="review-item-name">Patrycja Neitsch</p>
                                <span className="review-item-date">a year ago</span>
                            </div>
                        </div>
                        <p className="review-item-description">{t('reviews.rev5.des')}</p>
                    </div>
                </Slide>
            </div>
        </section>
    )
}