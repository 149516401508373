import React, { useContext, useEffect, useRef, useState } from "react";
import logo from '../assets/logo.svg';
import { useTranslation } from "react-i18next";
import { LanguageSelector } from "./language-selector";
import { useIntersectObserve } from "../hooks";

import '../styles/components/header.css'
import hamburger from '../assets/hamburger.svg';
import { ResizeContext } from '../context/resize-context';
import {Link, useNavigate } from 'react-router-dom';

export const Header = () => {
    const {t} = useTranslation();
    const headerRef = useRef();
    const entry = useIntersectObserve(headerRef);
    const isVisible = !!entry?.isIntersecting;
    const [hiddenNavList, setHiddenNavList] = useState(true);
    const {width, isTablet} = useContext(ResizeContext);

    const navigate = useNavigate();

    const navigation = [
        {title: t("menu.about_us"), link: '/about-us'},
        {title: t("menu.services"), link: '/services'},
        {title: t("menu.team"), link: '/team'},
        {title: t("menu.devices"), link: '/device'},
        // {title: t("menu.brands"), link: '/brands'},
        {title: t("menu.contacts"), link: '/contacts'},
    ];

    useEffect(() => {
        if (!hiddenNavList) {
            document.body.style.overflowY = 'hidden';
        }
        if (hiddenNavList) {
            document.body.style.overflowY = 'unset';
        }
    }, [hiddenNavList]);

    const onNavigationClick = (link) => {
        navigate(link)
    };

    return (
        <header ref={headerRef}>
            <div className={`header-inner ${isVisible ? 'full-width-content' : 'simplify'}`}>
                <div className='page-container top-line hide-content row'>
                    {hiddenNavList && <LanguageSelector shortName={isTablet}/>}
                    <Link to="/" className="logo">
                        <img src={logo} alt="logo" loading="eager" />
                    </Link>
                </div>
                <div className="content row">

                    {isTablet ?
                        <>
                            <div className="hamburger" onClick={() => setHiddenNavList(!hiddenNavList)}>
                                <img src={hamburger} alt="hamburger"/>
                            </div>
                            {!hiddenNavList &&
                                <div className="navigation-list-mobile">
                                    <nav className="navigation-list">
                                        {navigation?.map(({title, link}, idx) => (

                                            <Link
                                                key={idx}
                                                to={link}
                                                className='navigation-list-item'
                                                onClick={() => {onNavigationClick(link); setHiddenNavList(true)}}
                                            >
                                                {title}
                                            </Link>
                                        ))}
                                    </nav>

                                    <LanguageSelector/>
                                </div>
                            }
                        </>
                        :

                        <nav className="navigation-list row">
                            {navigation?.map(({title, link}, idx) => (
                                    <Link
                                        key={idx}
                                        to={link}
                                        className='navigation-list-item'
                                        onClick={() => {onNavigationClick(link); setHiddenNavList(true)}}
                                    >
                                        {title}
                                    </Link>
                            ))}
                        </nav>
                    }
                </div>
            </div>
        </header>
    )
}