import { Link, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import '../styles/components/breadcrumb.css'

const Breadcrumbs = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { key, id } = useParams();
    const pathnames = location.pathname.split("/").filter((x) => x);

    const breadcrumbNames = {
        home: t("breadcrumbs.home"),
        "about-us": t("breadcrumbs.about-us"),
        contacts: t("breadcrumbs.contacts"),
        team: t("breadcrumbs.team"),
        services: t("breadcrumbs.services"),
        device: t("breadcrumbs.devices"),
        cookie: t("breadcrumbs.cookie"),
        "privacy-policy": t("breadcrumbs.privacy-policy"),
    };

    // Динамічні назви для команди, сервісу та девайса
    const dynamicNames = {
        team: key ? t(`team.${key}.name`) : null,  // використовуємо динамічне ім'я для члена команди
        services: key ? t(`services.${key}.title`) : null,
        device: key ? t(`devices.${key}.title`) : null,
    };

    // Початковий масив breadcrumbs
    let breadcrumbList = [
        { name: breadcrumbNames.home, to: "/" }, // завжди додаємо головну сторінку
    ];

    // Додаємо категорію (наприклад, team)
    if (pathnames[0]) {
        const categoryName = breadcrumbNames[pathnames[0]] || pathnames[0];
        breadcrumbList.push({
            name: categoryName,
            to: `/${pathnames[0]}`,
        });
    }

    // Додаємо динамічні елементи (наприклад, член команди або інший елемент)
    if (pathnames[1]) {
        const dynamicName = dynamicNames[pathnames[0]] || pathnames[1]; // використовуємо динамічне ім'я для першого параметра
        breadcrumbList.push({
            name: dynamicName,
            to: `/${pathnames[0]}/${pathnames[1]}`,
        });
    }

    return (
        <nav className="breadcrum-flex text-gray text-sm py-2">
            <ul className="breadcrumb-items">
                {breadcrumbList.map((breadcrumb, index) => {
                    const isLast = index === breadcrumbList.length - 1;
                    return (
                        <li key={breadcrumb.to} className="flex items-center">
                            {/*<span className="mx-1">·</span>*/}
                            {isLast ? (
                                <span className="text-black font-medium">{breadcrumb.name}</span>
                            ) : (
                                <Link to={breadcrumb.to} className="text-gray hover:text-black">
                                    {breadcrumb.name}
                                </Link>
                            )}
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
};

export default Breadcrumbs;
