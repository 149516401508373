import React, {useContext} from 'react';
import {ResizeContext} from '../../context/resize-context';
import {useTranslation} from "react-i18next";
import {Page} from "../../components/page";
import '../../styles/components/contacts.css'
import Banner from '../../shared/components/banner';

import brandsPageBanner from '../../assets/contacts/contacts-page-banner.jpg';
import brandsPageBannerMobile from '../../assets/contacts/contacts-page-banner-mobile.jpg';

import addressImg1 from '../../assets/contacts/img-address-1.jpg';
import addressImgXs1 from '../../assets/contacts/img-address-xs-1.jpg';
import addressImg2 from '../../assets/contacts/img-address-2.jpg';
import addressImgXs2 from '../../assets/contacts/img-address-xs-2.jpg';

import mapIcon from '../../assets/contacts/map.svg';
import clockIcon from '../../assets/contacts/clock.svg';
import mailIcon from '../../assets/contacts/mail.svg';
import phoneIcon from '../../assets/contacts/phone.svg';


import Button from '../../shared/components/button';
import {bookVisitLink, instagramLink} from '../../constants';
import {Helmet} from "react-helmet-async";
import aboutUsPageBannerMobile from "../../assets/about-us/about-us-page-banner-mobile.jpg";
import Breadcrumbs from "../../components/breadcrumbs";

export const Contacts = () => {
    const {t} = useTranslation();
    const {isTablet} = useContext(ResizeContext);

    return (
        <Page>
            <Helmet>
                <title>{t('contacts.title')} | Klinika urody w Warszawie, Polsce</title>

                <meta property="og:title" content={`${t('contacts.title')} | Klinika urody w Warszawie, Polsce`} />
                <meta property="og:image" content={`${window.location.origin}${brandsPageBanner}`} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
            </Helmet>
            <Breadcrumbs />
            <Banner image={brandsPageBanner}
                    imageXs={brandsPageBannerMobile}>
               {t('contacts.title')}
            </Banner>
            <div className="contacts-page-content">
                <div className="contacts-top-img-block">
                    <div className="address-details">
                        <h2>Leo Beauty Clinic</h2>
                        <div className="icon-text-wrapper">
                            <img className="icon" src={mapIcon} width="18" height="18"></img>
                            <a href="https://maps.app.goo.gl/Jvq23pM6hmN7EvJp6?g_st=com.google.maps.preview.copy"
                               target="_blank">
                                <p>{t('contacts.adres1')}</p>
                            </a>
                        </div>
                        <div className="icon-text-wrapper">
                            <img className="icon" src={clockIcon} width="18" height="18"></img>
                            <p>{t('contacts.work_time')}</p>
                        </div>
                        <div className="icon-text-wrapper">
                            <img className="icon" src={mailIcon} width="18" height="18"></img>
                            <p>biuro@klinikastawki.pl</p>
                        </div>
                        <div className="icon-text-wrapper">
                            <img className="icon" src={phoneIcon} width="18" height="18"></img>
                            <a href="tel:+48224244646" target="_blank">
                                <p>+48 224 24 46 46</p>
                            </a>
                        </div>
                    </div>
                    <a href="https://maps.app.goo.gl/Jvq23pM6hmN7EvJp6?g_st=com.google.maps.preview.copy"
                       target="_blank"><img className="img-address"
                                            src={isTablet ? addressImgXs1 ?? addressImg1 : addressImg1}
                                            alt={addressImg1} loading="lazy"/>
                    </a>
                </div>
                <div className="contacts-top-img-block">
                    <a href="https://maps.app.goo.gl/9XxRwFrQzPP1J2nQ9?g_st=com.google.maps.preview.copy"
                       target="_blank">
                        <img className="img-address" src={isTablet ? addressImgXs2 ?? addressImg2 : addressImg2}
                             alt={addressImg2} loading="lazy"/>
                    </a>
                    <div className="address-details">
                        <h2>Leo Beauty Club</h2>
                        <div className="icon-text-wrapper">
                            <img className="icon" src={mapIcon} width="18" height="18"></img>
                            <a href="https://maps.app.goo.gl/9XxRwFrQzPP1J2nQ9?g_st=com.google.maps.preview.copy"
                               target="_blank">
                                <p>{t('contacts.adres2')}</p>
                            </a>
                        </div>
                        <div className="icon-text-wrapper">
                            <img className="icon" src={clockIcon} width="18" height="18"></img>
                            <p>{t('contacts.work_time')}</p>
                        </div>
                        <div className="icon-text-wrapper">
                            <img className="icon" src={mailIcon} width="18" height="18"></img>
                            <p>leobeautyclub@gmail.com</p>
                        </div>
                        <div className="icon-text-wrapper">
                            <img className="icon" src={phoneIcon} width="18" height="18"></img>
                            <a href="tel:+48663401010" target="_blank">
                                <p>+48 663 401 010</p>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="row book-visit-btn">
                    <Button onClick={() => window.open(bookVisitLink)}>
                        {t('common.book_visit')}
                    </Button>
                </div>
            </div>
        </Page>
    )
}