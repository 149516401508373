import React, { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ResizeContext } from '../../../context/resize-context';
import aboutUs from '../../../assets/videos/about-us-new.mp4'
import poster from '../../../assets/videos/poster.jpg'

import '../../../styles/components/about-us.css'
import Button from '../../../shared/components/button';

export const AboutUs = () => {
    const {t} = useTranslation();
    const {isTablet} = useContext(ResizeContext);
    const vidRef = useRef(null);
    const handlePlayVideo = () => {
        vidRef?.current?.play();
    }
    const [showMore, setShowMore] = useState(false);

    const handleToggle = () => {
        setShowMore(!showMore);
    };

    return (
        <section className="main-about-us-section">
            <h1 className="main-page-title">KLINIKA MEDYCYNY ESTETYCZNEJ W WARSZAWIE</h1>
            <div className="about-us-wrapper">
                <div className="about-us-info">
                    <p className="section-description">
                        {t('about_us.description')}
                    </p>
                    {showMore &&  <p className="section-description"> {t('about_us.description2')} </p>}
                    <div className="read-more-btn">
                        <Button type="accent-button theme-button-xs" onClick={handleToggle}>
                            {showMore ? t('common.read_less') : t('common.read_more')}
                        </Button>
                    </div>
                </div>

                <div className="about-us-video-container">
                    <video ref={vidRef} poster={poster} autoPlay loop muted playsInline
                           className="about-us-video">
                        <source src={aboutUs} type="video/mp4"/>
                    </video>
                </div>
            </div>

        </section>
    )
}