import React, {useContext, useState} from 'react';
import {ResizeContext} from '../../context/resize-context';
import {useTranslation} from "react-i18next";
import {Page} from "../../components/page";
import Breadcrumbs from "../../components/breadcrumbs";
import '../../styles/components/about-us-page.css'
import Banner from '../../shared/components/banner';
import aboutUsPageBanner from '../../assets/about-us/about-us-page-banner.jpg';
import aboutUsPageBannerMobile from '../../assets/about-us/about-us-page-banner-mobile.jpg';

import wellnessImg1 from '../../assets/about-us/img-wellness-1.jpg';
import wellnessImgXs1 from '../../assets/about-us/img-wellness-xs-1.jpg';
import specialistsImg2 from '../../assets/about-us/img-specialists-2.jpg';
import specialistsImgXs2 from '../../assets/about-us/img-specialists-xs-2.jpg';
import dailyImg2 from '../../assets/about-us/img-daily-2.jpg';
import dailyImgXs2 from '../../assets/about-us/img-daily-xs-2.jpg';


import Button from '../../shared/components/button';
import {bookVisitLink} from '../../constants';

import nescens from "../../assets/about-us/nescenslogo.png";
import philip from "../../assets/about-us/philipmartins.png";
import clinical from "../../assets/about-us/clinicallogo.png";
import myPureSkin from "../../assets/about-us/myPureSkinlogo.png";

import nescenspl from "../../assets/about-us/nescenspl.jpg";
import philippl from "../../assets/about-us/philippl.jpg";
import clinicalpl from "../../assets/about-us/clinicalpl.jpg";
import myPureSkinpl from "../../assets/about-us/myPureSkinpl.jpg";

import clubImg1 from "../../assets/about-us/club-img1new.jpg";
import clubImg2 from "../../assets/about-us/club-img2.jpg";

import clubImg1pl from "../../assets/about-us/club-img1pl.jpg";
import clubImg2pl from "../../assets/about-us/club-img2pl.jpg";

import uaFlag from "../../assets/about-us/icons8-ukraine-48.png";
import aeFlag from "../../assets/about-us/ae.png";


import {Slide} from "react-slideshow-image";
import ServiceCard from "../../shared/components/service-card";
import sliderArrowLeft from "../../assets/icons/arrow-left.svg";
import {Helmet} from "react-helmet-async";


export const AboutUs = () => {
    const {t, i18n} = useTranslation();
    const {isTablet, isMobile} = useContext(ResizeContext);

    const SalonList = [{src: clubImg1, link: "https://www.instagram.com/leobeautyclub.kyiv"}, {
        src: clubImg2,
        link: "https://www.instagram.com/leobeautyclub.dubai"
    }];
    const SalonListPl = [{src: clubImg1pl, link: "https://www.instagram.com/leobeautyclub.kyiv"}, {
        src: clubImg2pl,
        link: "https://www.instagram.com/leobeautyclub.dubai"
    }];

    const DistributorList = [nescens, philip, clinical, myPureSkin];
    const DistributorListPl = [nescenspl, philippl, clinicalpl, myPureSkinpl];

    const [reviews, setReviews] = useState([...SalonListPl, ...SalonListPl]);


    let currentSalonList = i18n.language === 'pl' ? SalonListPl : SalonList;
    let currentDistributorList = i18n.language === 'pl' ? DistributorListPl : DistributorList;

    const customArrowPreviewComponent = <div className='slider-navigation'><img src={sliderArrowLeft} alt="chevron"/>
    </div>;

    const properties = {
        prevArrow: customArrowPreviewComponent,
        nextArrow: customArrowPreviewComponent,
    }
    return (
        <Page>
            <Helmet>
                <title>{t('about_us.title')} | Klinika urody w Warszawie, Polsce</title>

                <meta property="og:title" content="{t('about_us.title')} | Klinika urody w Warszawie, Polsce"/>
                <meta property="og:image" content={`${window.location.origin}${aboutUsPageBannerMobile}`}/>
                <meta property="og:url" content={window.location.href}/>
            </Helmet>
            <Breadcrumbs/>
            <Banner image={aboutUsPageBanner}
                    imageXs={aboutUsPageBannerMobile}>
                {t('about_us.title')}
            </Banner>
            <div className="about-us-page-content">

                {isTablet ? (
                    <>
                        <div className="about-us-top-block">
                            <img className="" src={isTablet ? wellnessImgXs1 ?? wellnessImg1 : wellnessImg1}
                                 alt={wellnessImg1}/>
                            <div className="about-us-details">
                                <h2>{t('about_us.subtit')}</h2>
                                <div className="about-us-text-wrapper">

                                    <p>{t('about_us.des')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="about-us-top-block">
                            <img className="" src={isTablet ? specialistsImgXs2 ?? specialistsImg2 : specialistsImg2}
                                 alt={specialistsImg2}/>
                            <div className="about-us-details">
                                <h2>{t('about_us.subtit2')}</h2>
                                <div className="about-us-text-wrapper">
                                    <p>{t('about_us.des2')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="about-us-top-block">
                            <img className="" src={isTablet ? dailyImgXs2 ?? dailyImg2 : dailyImg2}
                                 alt={dailyImg2}/>
                            <div className="about-us-details">
                                <h2>{t('about_us.subtit3')}</h2>
                                <div className="about-us-text-wrapper">

                                    <p>{t('about_us.des3')}</p>
                                </div>
                            </div>

                        </div>

                    </>) : (
                    <>
                        <div className="about-us-top-block">
                            <div className="about-us-details">
                                <h2>{t('about_us.subtit')}</h2>
                                <div className="about-us-text-wrapper">

                                    <p>{t('about_us.des')}</p>
                                </div>
                            </div>
                            <img className="" src={isTablet ? wellnessImgXs1 ?? wellnessImg1 : wellnessImg1}
                                 alt={wellnessImg1}/>
                        </div>
                        <div className="about-us-top-block">
                            <img className="" src={isTablet ? specialistsImgXs2 ?? specialistsImg2 : specialistsImg2}
                                 alt={specialistsImg2}/>
                            <div className="about-us-details right-text">
                                <h2>{t('about_us.subtit2')}</h2>
                                <div className="about-us-text-wrapper">
                                    <p>{t('about_us.des2')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="about-us-top-block">
                            <div className="about-us-details">
                                <h2>{t('about_us.subtit3')}</h2>
                                <div className="about-us-text-wrapper">

                                    <p>{t('about_us.des3')}</p>
                                </div>
                            </div>
                            <img className="" src={isTablet ? dailyImgXs2 ?? dailyImg2 : dailyImg2}
                                 alt={dailyImg2}/>
                        </div>
                    </>
                )}
                <div className="row book-visit-btn">
                    <Button onClick={() => window.open(bookVisitLink)}>
                        {t('common.book_visit')}
                    </Button>
                </div>

                <section className="section-about-page full-width-content">
                    {!isMobile && (
                        <div className="distributor-list-grid page-container">

                            <div className="distributor-list-grid-item">
                                <img src={nescens}/>
                                <h6>{t('about_us.partner1')}</h6>
                            </div>
                            <div className="distributor-list-grid-item">
                                <img src={philip}/>
                                <h6>{t('about_us.partner2')}</h6>
                            </div>
                            <div className="distributor-list-grid-item">
                                <img src={clinical}/>
                                <h6>{t('about_us.partner3')}</h6>
                            </div>
                            <div className="distributor-list-grid-item">
                                <img src={myPureSkin}/>
                                <h6>{t('about_us.partner4')}</h6>
                            </div>


                        </div>
                    )}
                    {isMobile && (
                        <div className="services-section-mobile about-us-slide-mob">
                            <Slide autoplay={false} slidesToShow={1} {...properties}>
                                <div className="image-container">
                                    <div className="distributor-list-grid-item">
                                        <img src={nescens}/>
                                        <h6>{t('about_us.partner1')}</h6>
                                    </div>
                                </div>
                                <div className="image-container">

                                    <div className="distributor-list-grid-item">
                                        <img src={philip}/>
                                        <h6>{t('about_us.partner2')}</h6>
                                    </div>
                                </div>
                                <div className="image-container">
                                    <div className="distributor-list-grid-item">
                                        <img src={clinical}/>
                                        <h6>{t('about_us.partner3')}</h6>
                                    </div>
                                </div>
                                <div className="image-container">
                                    <div className="distributor-list-grid-item">
                                        <img src={myPureSkin}/>
                                        <h6>{t('about_us.partner4')}</h6>
                                    </div>

                                </div>

                            </Slide>
                        </div>
                    )}

                    <div className="full-width-content">
                        <div className="about-background-img">
                            <h1 className="about-img-title">{t('about_us.lbc1')}<br/>{t('about_us.lbc2')}</h1>
                            <Button onClick={() => window.open('https://leobeautyclinic.pl')}
                                    type="accent-button website-button">
                                {t('about_us.button')}
                            </Button>
                        </div>
                    </div>
                    <div className="distributor-title">
                        <p>{t('about_us.also')}</p>
                    </div>


                    <div className="club-list-grid page-container">
                        <div className="club-list-card">

                            <h3><img src={uaFlag} width="30"/> {t('about_us.title1')}</h3>
                            <div className="club-list-card-address">
                                <p>{t('about_us.address1')}</p>
                                <a href="tel:+380994443845"><p>+38 099 444 38 45</p></a>
                            </div>
                            <p><a href="https://www.instagram.com/leobeautyclub.kyiv" target="_blank">INSTAGRAM</a></p>
                        </div>

                        <div className="club-list-card">

                            <h3><img src={aeFlag} width="30"/> {t('about_us.title2')}</h3>
                            <div className="club-list-card-address">
                                <p>{t('about_us.address2')}</p>
                                <a href="tel:+971561691600"><p>+971 56 169 1600</p></a>
                            </div>
                            <p><a href="https://www.instagram.com/leobeautyclub.dubai" target="_blank">INSTAGRAM</a></p>
                        </div>

                    </div>

                </section>
            </div>
        </Page>
    )
}


