import React, {memo, useRef} from 'react';
import {Page} from "../../components/page";
import {PromoSlider} from './sections/promo-slider';
import {Services} from './sections/services';
import {Devices} from './sections/devices';
import {Brands} from './sections/brands';
import {Instagram} from './sections/instagram';
import {Reviews} from './sections/reviews';
import {AboutUs} from './sections/about-us';
import {Team} from './sections/team';
import {BookButton} from "../../components/book-button";
import {useIntersectObserve} from "../../hooks";
import { Helmet } from 'react-helmet-async';
import logo from '../../assets/leo-clinic.webp';



export const Clinic = memo(() => {

    const jsonLdData = {
        "@context": "http://schema.org",
        "@type": "Organization",
        "name": "Leo beauty clinic",
        "url": window.location.href,
        "logo": `${window.location.origin}${logo}`,
        "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+48224244646",
            "contactType": "Customer Service",
        },
    };

    return (

        <Page>
            <Helmet>
                <title>Leo Beauty Clinic | Klinika urody w Warszawie, Polsce</title>
                <meta name="description" content="Centrum odnowy biologicznej | Leobeautyclinic.pl | w Warszawie ✔️ Szeroki zakres usług ✔️ Doświadczeni specjaliści ✔️ Gwarancje jakości" />

                <meta property="og:title" content="Leo beauty clinic" />
                <meta property="og:description" content="Centrum odnowy biologicznej | Leobeautyclinic.pl | w Warszawie ✔️ Szeroki zakres usług ✔️ Doświadczeni specjaliści ✔️ Gwarancje jakości" />
                <meta property="og:image" content={`${window.location.origin}${logo}`} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:site_name" content="Leo Beauty Clinic"/>
                <meta property="og:type" content="website" />

                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLdData) }}
                />

            </Helmet>
            <PromoSlider></PromoSlider>
            <AboutUs></AboutUs>
            <Services></Services>
            <Devices></Devices>
            <Reviews></Reviews>
            <Team></Team>
            <Brands></Brands>
            <Instagram></Instagram>
            <BookButton/>
        </Page>
    )
})