import React, {memo} from 'react';
import {Page} from "../../components/page";
import Banner from '../../shared/components/banner';
import servicePageBanner from '../../assets/banners/service-page-banner.jpg'
import servicePageBannerMobile from '../../assets/banners/service-page-banner-mobile.jpg'
import AllServices from './sections/all-services';
import {useTranslation} from 'react-i18next';
import {Helmet} from "react-helmet-async";
import logo from "../../assets/leo-clinic.webp";
import Breadcrumbs from "../../components/breadcrumbs";

export const Services = memo(() => {
    const {t} = useTranslation();

    const jsonLdData = {
        "@context": "http://schema.org",
        "@type": "Organization",
        "name": "Leo beauty clinic",
        "url": window.location.href,
        "logo": `${window.location.origin}${logo}`,
        "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+48224244646",
            "contactType": "Customer Service",
        },
    };

    return (
    <Page>

        <Helmet>
            <title>{t('services.title')}| Klinika urody w Warszawie, Polsce</title>
            <meta name="description"
                  content="Centrum odnowy biologicznej | Leobeautyclinic.pl | w Warszawie ✔️ Szeroki zakres usług ✔️ Doświadczeni specjaliści ✔️ Gwarancje jakości"/>

            <meta property="og:title" content={t('services.title')}/>
            <meta property="og:description"
                  content="Centrum odnowy biologicznej | Leobeautyclinic.pl | w Warszawie ✔️ Szeroki zakres usług ✔️ Doświadczeni specjaliści ✔️ Gwarancje jakości"/>
            <meta property="og:image" content={`${window.location.origin}${servicePageBannerMobile}`}/>
            <meta property="og:url" content={window.location.href}/>
            <meta property="og:site_name" content="Leo Beauty Clinic"/>
            <meta property="og:type" content="website"/>

            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{__html: JSON.stringify(jsonLdData)}}
            />

        </Helmet>

        <Breadcrumbs />
        <Banner image={servicePageBanner}
                imageXs={servicePageBannerMobile}>
            {t('services.title')}
        </Banner>
        <AllServices></AllServices>
    </Page>

)

})