import React, { useContext, useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import '../styles/components/footer.css';
import { ResizeContext } from "../context/resize-context";
import { instagramLink, facebookLink } from "../constants";
import instagram from '../assets/instagram.svg';
import facebook from '../assets/facebook.svg';
import {Link, useNavigate} from "react-router-dom";
import ReCAPTCHA from 'react-google-recaptcha';

export const Footer = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const RECAPTCHA_SITE_KEY = process.env.REACT_APP_SITE_KEY;

    const recaptcha = useRef();
    const [email, setEmail] = useState('');

    const [recaptchaError, setRecaptchaError] = useState('');

    const [successMessage, setSuccessMessage] = useState('');

    const [emailError, setEmailError] = useState('');

    const resetForm = () => {
        setEmail('');
        recaptcha.current.reset();
    };

    async function submitForm(event) {
        event.preventDefault();
        const captchaValue = recaptcha.current.getValue();

        if (!captchaValue) {
            setRecaptchaError('Please verify the reCAPTCHA!');
        } else {
            setRecaptchaError('');
            try {
                const response = await fetch('https://cabinet.leobeautyclub.com/api/email/index', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'cGCnLq3pHkJ2kfpM8286hLfAQKsc6TF7',
                    },
                    body: JSON.stringify({
                        email: email,
                    }),
                });

                const data = await response.json();


                if (response.ok) {
                    setSuccessMessage('Form submission successful!');
                    resetForm();
                    setTimeout(() => {
                        setSuccessMessage('');
                    }, 3000);
                } else {
                    if (data.errors && data.errors.email) {
                        setEmailError(`${data.errors.email.join(', ')}`);
                    } else {
                        setRecaptchaError(`Form submission failed: ${data.message}`);
                    }
                }
            } catch (error) {
                // setSuccessMessage(' ');
                setRecaptchaError('An error occurred while submitting the form.');
            }
        }
    }

    const navigation = [
        { title: t("menu.about_us"), link: '/about-us' },
        { title: t("menu.services"), link: '/services' },
        { title: t("menu.team"), link: '/team' },
        { title: t("menu.devices"), link: '/device' },
        // { title: t("menu.brands"), link: '/brands' },
        { title: t("menu.contacts"), link: '/contacts' },
    ];

    const { isTablet } = useContext(ResizeContext);

    const onNavigationClick = (link) => {
        navigate(link);
    };

    return (
        <footer>
            <div className="page-container">
                <div className="footer-nav-list">
                    {navigation?.map(({ title, link }, idx) => (
                        <div key={idx} className={"footer-nav-card"}>
                            <Link to={link} className="footer-nav-list-item">
                                {title}
                            </Link>
                        </div>
                    ))}
                </div>
                {isTablet ? (
                    <>
                        <div className="footer-frame-address">
                            <p className="mob-title-text">{t("common.work")}</p>
                            <div className="footer-text-center">
                                <p>{t("common.days")}</p>
                                <p>{t("common.time")}</p>
                            </div>
                            <p className="mob-title-text">{t("menu.contacts")}</p>
                            <div className="footer-text-right">
                                <p><a href="tel:+48224244646">+48 22 424 46 46</a></p>
                                <p><a href="mailto:biuro@klinikastawki.pl">biuro@klinikastawki.pl</a></p>
                                <p>{t('common.cooperation_offers')}</p>
                                <p><a href="mailto:leobeautyclub@gmail.com"> leobeautyclub@gmail.com</a></p>
                            </div>
                            <div className="footer-text-left">
                                <p>{t('common.address')}</p>
                                <p>{t('common.address2')}</p>
                                <p>{t('common.address3')}</p>
                            </div>
                            <div className="footer-social">
                                <a href={instagramLink} target="_blank"><img src={instagram} alt="instagram" /></a>
                                <a href={facebookLink} target="_blank"><img src={facebook} alt="facebook" /></a>
                            </div>
                        </div>
                        <div className="footer-frame-input">
                            <form onSubmit={submitForm}>
                                <div className="footer-form">
                                    <div className="footer-input">
                                        <input className="footer-text" name="Email"
                                               type={'email'}
                                               value={email}
                                               onChange={(event) => setEmail(event.target.value)}
                                               placeholder={t('common.input.placeholder')} required />
                                    </div>
                                    <button className="footer-button" type="submit">{t('common.input.button')}</button>
                                </div>
                                {emailError && <div style={{ display: 'flex', justifyContent: 'center', color: '#e04646', marginTop: '5px' }}>{emailError}</div>}
                                {successMessage && <div style={{ display: 'flex', justifyContent: 'center', color: '#ffffff', marginTop: '5px' }}>{successMessage}</div>}
                                <br/>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <ReCAPTCHA
                                        ref={recaptcha}
                                        sitekey={RECAPTCHA_SITE_KEY}
                                    />

                                </div>
                                {recaptchaError && <div style={{ display: 'flex', justifyContent: 'center', color: 'red', marginTop: '10px' }}>{recaptchaError}</div>}

                            </form>
                        </div>
                        <div className="footer-bottom">
                            <div className="bottom-grid">
                                <p className="footer-bottom-text-right">
                                    <Link to="/privacy-policy">{t('common.privacy')}</Link>
                                </p>
                                <p className="footer-bottom-text-right">
                                    <Link to="/cookie">{t('common.cookie')}</Link>
                                </p>
                            </div>
                            <div className="bottom-grid">
                                <p className="footer-bottom-text-left">{t('common.copyright')}</p>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="footer-frame-input">
                            <form onSubmit={submitForm}>
                                <div className="footer-form">
                                    <div className="footer-input">
                                        <input className="footer-text" name="Email"
                                               type={'email'}
                                               value={email}
                                               onChange={(event) => setEmail(event.target.value)}
                                               placeholder={t('common.input.placeholder')} required />
                                    </div>
                                    <button className="footer-button" type="submit">{t('common.input.button')}</button>
                                </div>
                                {emailError && <div style={{ display: 'flex', justifyContent: 'center', color: '#e04646', marginTop: '5px' }}>{emailError}</div>}
                                {successMessage && <div style={{ display: 'flex', justifyContent: 'center', color: '#ffffff', marginTop: '5px' }}>{successMessage}</div>}
                                <br/>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <ReCAPTCHA
                                        ref={recaptcha}
                                        sitekey={RECAPTCHA_SITE_KEY}
                                    />

                                </div>
                                {recaptchaError && <div style={{ display: 'flex', justifyContent: 'center', color: 'red', marginTop: '10px' }}>{recaptchaError}</div>}

                            </form>
                        </div>
                        <div className="footer-frame-address">
                            <div className="footer-text-left">
                                <p>{t('common.address')}</p>
                                <p>{t('common.address2')}</p>
                                <p>{t('common.address3')}</p>
                            </div>
                            <div className="footer-text-center">
                                <p>{t("common.work")}</p>
                                <p>{t("common.days")}</p>
                                <p>{t("common.time")}</p>
                            </div>
                            <div className="footer-text-right">
                                <p><a href="tel:+48224244646">+48 22 424 46 46</a></p>
                                <p><a href="mailto:biuro@klinikastawki.pl">biuro@klinikastawki.pl</a></p>
                                <p>{t('common.cooperation_offers')} <a href="mailto:leobeautyclub@gmail.com">leobeautyclub@gmail.com</a></p>
                            </div>
                        </div>
                        <div className="footer-bottom">
                            <div className="bottom-grid">
                                <p className="footer-bottom-text-center"><a href={instagramLink} target="_blank">INSTAGRAM</a></p>
                                <p className="footer-bottom-text-center"><a href={facebookLink} target="_blank">FACEBOOK</a></p>
                            </div>
                            <div className="bottom-grid">
                                <p className="footer-bottom-text-right">
                                    <Link to="/privacy-policy">{t('common.privacy')}</Link>
                                </p>
                                <p className="footer-bottom-text-right">
                                    <Link to="/cookie">{t('common.cookie')}</Link>
                                </p>
                            </div>
                        </div>
                        <div className="footer-bottom footer-center">
                            <div className="bottom-grid">
                                <p className="footer-bottom-text-left">{t('common.copyright')}</p>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </footer>

    );
}

